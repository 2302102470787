export const m = {
    ysq: 'authorized',
  wsq:'unauthorized',
  jrl: ' Joined the FILPlay',
    
   bqhd:'FILmine introduction',
  hdxq: 'FILmine100% distributed miner marketing plan is based on HECO, huobi ecological public chain, to develop DAPP smart contract. The contract passes the audit, and the chain is operated safely. The mining pool is directly corresponding to nodes for joint mining, and the data is real and veriable, transparent and open. Each miner is given double computing power value, computing power value is encapsulated and computing power is combined with mining for 1080 days. According to the average value of the whole network, monetary output income is obtained. According to the distribution rules, technical operation and maintenance is allocated 20%, hard disk cost is allocated 10%, and FILmine miners are allocated 70%, of which 25% can be extracted in coins and 75% can be linearly released for 180 days. Miners can also share sermons, joint mining to get more money, leverage fission FIL assets, promote more miners long-term belief to hold FIL, break the monopoly of capitalists to harvest, promote the development of IPFS interagency file storage track, let more people access to IPFS brought to human privacy storage, safe storage, low cost storage retrieval and other convenience, In the FILmine marketing plan to achieve all miners, all mines.',
  

  jiaocheng:'Course',

  ljcy: 'Participate in FILmine',
  zcyz: 'The total participants',

  zrcy:'Participated in HFIL quantity yesterday',
  jqrcy: 'The number of HFIL participants in the past seven days',
  kc: 'Ore pool',
  ktsl: 'To mention the number',
  jtsc:'Static lock up',
  ljsf: 'Available',
  kysl:'Encapsulation is force',
  xhsl: 'Destruction is force',
  mrmtcc: 'Output per T per day',
  ljsy: 'Mining earnings',
  scsl:'Linear release for 180 days',
  sfsl:'Release amount',
  minx: 'Detailed',
  tix:'Withdraw',

  jz: 'A',
  gp: 'B',
  dqjzdj: 'Current A Level',
  qyjz: 'Enable the A',
  dqgpdj: 'Current B level',
  qygp: 'Enable the B',
  qyjzhgp: 'Enable A and B',
  jzhgptskt: 'A and B must be opened at the same time',
  buy:'Open',
  yqy:'Is enabled',

  yfmx: 'Calculate  detail',
  slmx: 'Hashrate detail',
  wkmx:'Mining detail',
  ktslbz:'The number of withdrawn coins is insufficient',
  tq: 'Extract the FIL',
  bzs: 'currency',
  zzdz: 'Transfer the address',
  asdds:'Type or long press the paste address,(HECO) channel',
  sls: 'amount ',
  zscdw:'The minimum transfer amount is ',
  sxf: 'service charge ',
  tjiao: 'Submit',
  tjiaolog: 'Submiting...',
  
  kgjzsy: 'Miner A payoff',
  kggpsy :'Miner B payoff',
  tdzrcy: 'Yesterday the team invested in HFIL',
  tdjqrcy:'The team invested in HFIL accumulatively',
  ljqy:'FILmine is enabled accumulatively',
  ljfjz:'Enable FILmine values',

  wdtj: 'Share the miners',
    dz: 'Address',
  time:'Time',
  tibsuc: 'Mention money success',
  tiberr:'Mention money failed',
 yddzme:'Maximum daily withdrawals have been reached',

  none: 'FILmine',
  jdll: 'United mining pool',
  jdb: 'United mining pool list',
  fzjd: 'See node number',
  jd: 'Node',
    jdfh:'Node bonus can be mentioned FIL',
  gbs: 'Close',
      noader:'The invitation address you bound does not meet the binding requirements!',
  kjz: 'Calculate',
  

  dqjrhb: 'Join partners within 24 hours',
  cyyzq: 'Participants have earned',
  zzc: 'Total assets',
  wdby: 'Single game',
  di: 'Processing',
  jjc:'prize pool',
  kjdjs: 'Lottery countdown',
  dqlxlb: 'Current Leading List',
  zk: 'an',
  sq:'Pack up',
  px: 'Sort',
  dizhi: 'Address',
  yjjl: 'Expected reward',
  loading: 'Loading...',
  zzqb: 'Support the purse',
  qbsqtt: 'Wallet authorizes your account',
  sqinfo: 'To use all the functions of your personal account, use Wallet Authorization',
  sqbtn: 'The wallet authorization',
  sqload:'The authorization...',
  dltt: 'Log in to your account',
  dlinfo: 'To use all the features of your personal account, use Automatic Login',
  dlbtn: 'Automatic login',
  xzlang: 'Select language',
  whqyqdz: 'The inviter\'s address information was not obtained',
    bangsuc: "Binding success",
  banger: 'Binding failure',
  qxsq: 'Please authorize first!',
  bdyqrdz:'Please bind the inviter\'s address!',

  
    yqlink:'Invite link',
  yqinfo: 'Share it with your partner via the invite link',
  yqr: 'Referees',

  fzcg: 'Copy success',
  fzsb: 'Copy the failure',
  buysuc: 'Open the success',
  buyer: 'Open the failure',
  reasuc: 'Refresh the success',
  hzhb: 'Partners',
  ftjb: 'After cast level',
  grztdhb: 'Personal straight push partner',
  sjhl: 'It\'s coming down from above',
  xmhl: 'It\'s coming down',
  yjhb: 'A partner over the top',
  zfgsj: 'Pay off a superior',
  
  
  yjszgj: 'The highest level has been reached!',
  yjsftzdz: 'It is already the maximum of the overthrow!',

  bdtt: 'Binding the superior',
  bdinfo: 'Please enter the reference\'s wallet address',
  bdbtn: 'Confirm',
    bdload:'Loading...'
}