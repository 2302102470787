import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userWallet: "" || sessionStorage.getItem("userWallet"),
    sid: "" || sessionStorage.getItem("sid"),
    myID: "" || sessionStorage.getItem("myID"),
    phone: '' || sessionStorage.getItem('phone'),
    intID: "" || sessionStorage.getItem("intID"),
    myTrx: "" || localStorage.getItem("myTrx"),
    myFst: "" || localStorage.getItem("myFst"),
    myDmcc:"" || localStorage.getItem("myDmcc"),
    language: localStorage.getItem('yy') || "zh-CN",
     theame:localStorage.getItem('theame') || '0',
    conid:"" || sessionStorage.getItem("conid"),
    anliid:""|| sessionStorage.getItem("anliid"),
  },
  mutations: {
    userWallet(state, val) {
      state.userWallet = val;
      sessionStorage.setItem("userWallet", val);
    },
    language(state,val){
      state.language = val
      localStorage.setItem('yy', val)
    },
    phone(state, val) {
      state.phone = val
      sessionStorage.setItem('phone', val)
    },
    sid(state, val) {
      state.sid = val;
      sessionStorage.setItem("sid", val);
    },
    conid(state,val){
      state.conid = val;
      sessionStorage.setItem("conid", val);
    },
    anliid(state,val){
      state.anliid= val;
      sessionStorage.setItem("anliid", val);
    },
    myID(state, val) {
      state.myID = val;
      sessionStorage.setItem("myID", val);
    },
    intID(state, val) {
      state.intID = val;
      sessionStorage.setItem("intID", val);
    },
    myTrx(state, val) {
      state.myTrx = val;
      localStorage.setItem("myTrx", val);
    },
    myFst(state, val) {
      state.myFst = val;
      localStorage.setItem("myFst", val);
    },
    myDmcc(state, val) {
      state.myDmcc = val;
      localStorage.setItem("myDmcc", val);
    },
      theame(state, val) {
      state.theame = val;
      localStorage.setItem("theame", val);
    },
  },
  actions: {},
  modules: {},
  getters: {
    userWallet: (state) => state.userWallet,
    sid: (state) => state.sid,
    myID: (state) => state.myID,
    phone: (state) => state.phone,
    intID: (state) => state.intID,
    myTrx: (state) => state.myTrx,
    myFst: (state) => state.myFst,
    myDmcc: (state) => state.myDmcc,
    language: (state) => state.language,
     theame: (state)=>state.theame,
    conid:(state)=>state.conid,
    anliid:(state)=>state.anliid,
  },
});
