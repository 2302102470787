<template>
  <div class="main">
<div class="mtoph5" v-if="show">
  <div class="left">
  <div class="logo"><img src="../assets/indeximg/logo.png" alt="LOGO" ></div>
  </div>  
</div>
<div class="mtop" v-else>
  <div class="left">
  <div class="logo"><img src="../assets/indeximg/logo.png" alt="LOGO" ></div>
  <div class="txt">
    <div class="t1">WELCOME TO CUPID LOVE METAVERSE </div>
    <div class="t2"> A limited NFT collection，in which the token itself doubles as your qualification to enjoy various privileges and rights in the Cupid Love Metaverse. Cupid love metaverse is open! Join in with us.</div>
  </div>
  </div>
  <div class="btn" @click="topage">Enter</div>
</div>
<div class="content">
</div>
<div class="txth5" v-if="show">
  <div class="txt">
    <div class="t1">WELCOME TO CUPID LOVE METAVERSE </div>
    <div class="t2"> A limited NFT collection，in which the token itself doubles as your qualification to enjoy various privileges and rights in the Cupid Love Metaverse. Cupid love metaverse is open! Join in with us.</div>
  </div>
  <div class="btn" @click="topage">Enter</div>
</div>

 <div class="mbottom" :class="show?'h5':''">
  <div class="sbox" v-if="show">
       <div class="logos">
    </div>
    <div class="emcard">
  <div class="embox">
    <div class="txts">GET ON THE LIST</div>
    <div class="email">
  <input type="text" class="input" placeholder="Email Address">
    </div>    
    </div>
    <div class="txtx" @click="topage('/detail')">
  CLM Terms & Conditions
</div>
</div>
 
    <div class="outer">
<div class="imgline">  
</div>


 </div> 

  </div>
  <div class="sbox" v-else>
  <div class="embox">
    <div class="txts">GET ON THE LIST</div>
    <div class="email">
  <input type="text" class="input" placeholder="Email Address">
    </div>    
    </div>
    <div class="logos">
      <img src="../assets/indeximg/logo.png" alt="LOGO">
    </div>
    <div class="outer">
<div class="imgline">  
</div>
<div class="txtx" @click="topage('/detail')">
CLM Terms & Conditions
</div>

 </div> 
  </div>


  </div>
  </div>
</template>

<script>
export default {
  data() {
   return{
     show:false,
     userWallet:'',
     email:""
   }
  }, 
       mounted() {
    if (this._isMobile()) {
      this.show=true;
    } else {
      this.show=this.$store.getters.phone;
    }    
  },
  watch: {
      '$store.getters.phone': function (val) {
      this.show = val
    },
    deep: true,
    immediate: true,
   },
  methods: {
    cread(e){
        this.userWallet=e
    },
      _isMobile() {
   let flag = navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
 return flag;   
},
    topage(){     
       this.$router.replace({ path: "index" })
        this.$store.commit("sid", '');
    },
      ding(){
    if(this.email){
       const reg = /^([a-zA-Z0-9]+[-_.]?)+@[a-zA-Z0-9]+.[a-z]+$/;
       if(reg.test(this.email)){
     this.$toast('Subscribe to the success');
     this.email=""
       }

    }else{
        this.$toast('Please enter your email number');
    }
  },
  }
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";
$t-mf: "Arial";
$t-f: "DINAlternate-Bold";
.main{
  background: #F65080;
  width: 100%;
  height: 100%;
     min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  .mtop{
    display: flex;
    justify-content: space-between;
    width: 1280px;
    margin: 0 auto;
    height: 240px;
    align-items: center;
    .left{
      display: flex;
      align-items: center;
    flex: 1;
    .logo{
        width: 125px;
        height: 145px;
      img{
        width: 125px;
        height: 145px;
      }
    }
    .txt{
        font-family: $t-mf;
        color: #FFFFFF; 
        text-align: left; 
        width: 573px;  
        margin-left: 72px;     
       
      .t1{
        font-size: 46px;   
       font-weight: bold;
      }
      .t2{
         font-size: 18px;
          
      }
    }
    }
    .btn{
      width: 310px;
      height: 62px;
      text-align: center;
      line-height: 62px;
      border-radius: 20px;
       font-family: $t-mf;
      color: #F65080;
      font-size: 23px;
      font-weight: bold;
      background: #FFFFFF;
      box-shadow: 0px 10px 18px 0px rgba(207, 78, 112, 0.18);
       cursor: pointer;
    }
  }
  .mtoph5{
    display: flex;
    justify-content: center;
    padding: 40px ;
    .left{
      justify-content: center;
      .logo{
        width: 172px;
        height: 200px;
        img{
            width: 172px;
        height: 200px;
        }
      }
    }
  }

  .content{
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .txth5{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
     .txt{
        font-family: $t-mf;
        color: #FFFFFF; 
        text-align: left;
        margin: 32px;  
       
      .t1{
        font-size: 46px;   
       font-weight: bold;
      }
      .t2{
         font-size: 18px;
          
      }
    }
     .btn{
      width: 310px;
      height: 62px;
      text-align: center;
      line-height: 62px;
      border-radius: 20px;
       font-family: $t-mf;
      color: #F65080;
      font-size: 23px;
      font-weight: bold;
      background: #FFFFFF;
      box-shadow: 0px 10px 18px 0px rgba(207, 78, 112, 0.18);
       cursor: pointer;
       margin: 32px;
    }
  }
 .mbottom{    
    background-size: 100% 100%;
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .sbox{
      width: 1280px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    
    .embox{      
    
      .txts{
      font-size: 20px;
      font-family: $t-mf;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 34px;
      width: 321px;
      text-align: left;
      }
      .email{
        width: 321px;
        height: 57px;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        // padding:0 10px;
        .input{
            border: none;
            font-size: 20px;
            width: calc(100% - 84px);
            margin-left:23px;
        }
        .send{
          width: 87px;
          height: 40px;
           cursor: pointer;
           margin-right: 10px;
        }
      }
    }      

    .logos{
       flex: 1;
      height: 100%;
      img{
        width: 174px;
        height: 211px;  
      }
    }
    .outer{
      .imgline{
        display:inline-block;
        white-space:nowrap;
        margin-bottom: 20px;
        img{
          width: 24px;
          height: 20px;
          margin-right: 14px;
        }
      }
         .txtx{
      
height: 20px;
font-size: 18px;
font-family: $t-mf;
font-weight: 400;
text-decoration: underline;
color: #FFFFFF;
line-height: 34px;
 cursor: pointer;
      }

    }
  }
  
}
  .h5{
    .sbox{
      width: 100%;
    display: flex;
    margin: 30px auto;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .emcard{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
          border-top: 1px solid #f8f8f8;
          margin-top: 33px;
    .embox{
        margin: 20px 33px;
          overflow: hidden;
      .txts{
      font-size: 20px;
      font-family: $t-mf;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 34px;
      width: 321px;
      text-align: left;
      }
      .email{
        width: 321px;
        height: 57px;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .input{
            border: none;
            width: calc(100% - 84px);
            margin-left: 23px;
        }
        .send{
          width: 87px;
          height: 40px;
           cursor: pointer;
           margin-right: 10px;
        }
      }
    }
.txtx{
      
      margin: 33px;
height: 20px;
font-size: 18px;
font-family: $t-mf;
font-weight: 400;
text-decoration: underline;
color: #FFFFFF;
line-height: 34px;
 cursor: pointer;
      }
    }
    .logos{
      width: 100%;
      height: 100%;
      img{
          width: 174px;
        height: 211px;  
      }
    }
    .outer{
      width: 100%;
      .imgline{
        display:inline-block;
        white-space:nowrap;
        margin-bottom: 20px;
        img{
          width: 38px;
          height: 32px;
          margin: 35px;
        }
      }
   
    }
  }
  }
}

</style>