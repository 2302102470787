import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/active',
    name: 'Active',
    component: () => import('@/views/Active.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('@/views/gallery.vue')
  },
  {
    path: '/gallerypc',
    name: 'gallerypc',
    component: () => import('@/views/gallerypc.vue')
  },
  {

    path: '/collection',
    name: 'collection',
    component: () => import('@/views/collection.vue')
  },
  {

    path: '/customizing',
    name: 'customizing',
    component: () => import('@/views/customizing.vue')
  },

  {

    path: '/about',
    name: 'about',
    component: () => import('@/views/about.vue')
  },

  {
    path: '/recruiting',
    name: 'recruiting',
    component: () => import('@/views/recruiting.vue')
  },
  {
    path: '/recuiDetail',
    name: 'recuiDetail',
    component: () => import('@/views/recuiDetail.vue')
  },


  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/help.vue')
  },
  {
    path: '/help1',
    name: 'help1',
    component: () => import('@/views/help1.vue')
  },

  {
    path: '/newsCenter',
    name: 'newsCenterr',
    component: () => import('@/views/team.vue')
  },
  {
    path: '/teamdetal',
    name: 'teamdetal',
    component: () => import('@/views/teamdetal.vue')
  },
  {
    path: '/save',
    name: 'save',
    component: () => import('@/views/save.vue')
  },
  {
    path: '/chatAi',
    name: 'chatAi',
    component: () => import('@/views/chatAi.vue')
  },
  {
    path: '/Agreement',
    name: 'Agreement',
    component: () => import('@/views/Agreement.vue')
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue')
  },
  {
    path: '/digital',
    name: 'digital',
    component: () => import('@/views/digital.vue')
  },

  {
    path: '/shoppingMall',
    name: 'shoppingMall',
    component: () => import('@/views/shoppingMall.vue')
  },
  {
    path: '/chainReform',
    name: 'chainReform',
    component: () => import('@/views/chainReform.vue')
  },
  

  {

    path: '/detail',
    name: 'detail',
    component: () => import('@/views/detail.vue')
  },

  {

    path: '/wky',
    name: 'wky',
    component: () => import('@/views/wky.vue')
  },
  {

    path: '/computing',
    name: 'computing',
    component: () => import('@/views/computing.vue')
  },
  

]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    //设置回到顶部
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0
    }
  },
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})
export default router