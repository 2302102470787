export default {
	//客户案例
	projectCate: "/v1/projectCate",
	casusList: "v1/casusList",
	//新闻
	newsCenter: "v1/newsCenter",
	articleList: "v1/articleList",
	//人才招聘
	articleTypeList: "v1/articleTypeList",

	cateList: "v1/cateList",

	menu: "v1/menu",

	casusList: "v1/casusList",

	projectCate: "v1/projectCate",

	craftCate: "v1/craftCate",
};
