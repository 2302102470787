import axios from "axios";
import router from "@/router";
import Vue from "vue";
import store from "@/store";
router.beforeEach((to, from, next) => {
	next();
});
let this_ = new Vue({
	router,
});
axios.create({
	// headers: { 'Content-Type': 'application/json;charset=UTF-8' },
	timeout: 600000,
});
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = process.env.VUE_APP_SERVE_URL;
// 请求拦截
axios.interceptors.request.use(
	(config) => {
		// 1. 这个位置就请求前最后的配置
		// 2. 当然你也可以在这个位置 加入你的后端需要的用户授权信息

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// 响应拦截
axios.interceptors.response.use(
	(response) => {
		// 请求成功
		// 1. 根据自己项目需求定制自己的拦截
		// 2. 然后返回数据
		// //console.log(response)
		if (response.data.code) {
			if (response.data.code != 200) {
				this_.$toast(response.data.msg);
			}
			if (
				response.data.code == 400 &&
				response.data.msg == "请登录后操作"
			) {
			}
		}

		return response;
	},
	(error) => {
		// 请求失败
		if (error && error.response) {
			switch (error.response.status) {
				case 400:
					// 对400错误您的处理\

					break;
				case 401:
					// 对 401 错误进行处理

					break;
				case 404:
					// 对 404 错误进行处理

					break;
				case 500:
					//对 500 错误进行处理

					break;
				default:
					// 如果以上都不是的处理
					return Promise.reject(error);
			}
		}
	}
);
