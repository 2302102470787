export const m = {
  ysq: '已授权',
  wsq:'未授权',
  jrl: ' 加入了FILmine',



  bqhd:'FST介绍',
  hdxq: 'FST通证是由Defi发烧友自发共建的翻身通证，强调多维度分散持有和一系列革命性突破。FST希望更多平民玩家参与树立新标杆。',


  jiaocheng:'教程',


  ljcy: '参与FILmine',
  zcyz: '总参加者',

  zrcy:'昨日团队新增FIL矿工',
  jqrcy: '近七日团队新增FIL矿工',
  kc: '矿池',
  ktsl: '可提币总量',
  jtsc:'静态锁仓',
  ljsf: '可用',
  kysl:'封装算力',
  xhsl: '销毁算力',
  mrmtcc: '每日每T产出',
  ljsy: '挖矿收益',
  scsl:'180天线性释放',
  sfsl:'释放可提币量',
  minx: '明细',
  tix:'提币',

  jz: 'A',
  gp: 'B',
  dqjzdj: '当前A等级',
  qyjz: '启用A',
  dqgpdj: '当前B等级',
  qygp: '启用B',
  qyjzhgp: '启用A和B',
  jzhgptskt: 'A和B须同时开通',
  buy: '启用',
  yqy: '已启用',
  
  yfmx: '算力值明细',
  slmx: '算力明细',
  wkmx: '产币明细',
  
  ktslbz:'可提币数量不足',
  tq: '提取FIL',
  bzs: '币种',
  zzdz: '收币地址',
  asdds:'输入或长按粘贴地址,请选择（HECO）通道',
  sls: '数量',
  zscdw:'最小转账数量',
  sxf: '手续费',
  tjiao: '提交',
  tjiaolog: '提交中...',
  
  kgjzsy: '矿工A收益',
  kggpsy :'矿工B收益',

  tdzrcy: '昨日团队新增FILmine',
  tdjqrcy:'团队累计FILmine',
  ljqy:'累计启用FILmine',
  ljfjz:'启用FILmine价值',
  wdtj: '分享矿工',
  yddzme:'已达到每日最大提现次数',
  
  dz: '地址',
  time: '时间',
  tibsuc: '提币成功',
  tiberr:'提币失败',

  none:'暂无',
  jdll: '联合矿池',
  jdb: '联合矿池列表',
  fzjd: '查看矿池',
  jd: '节点',
  jdfh:'节点分红可提FIL',
  gbs: '关闭',
  
  noader: '您绑定的邀请地址不符合绑定要求！',
  kjz:'算力值',



  dqjrhb: '24小时内加入的伙伴',
  cyyzq: '参加者已赚取',
  zzc: '总资产',
  wdby: '尾单博弈',
  di: '进行中',
  jjc:'奖金池',
  kjdjs: '开奖倒计时',
  dqlxlb: '当前领先列表',
  zk: '展开',
  sq:'收起',
  px: '排序',
  dizhi: '地址',
  yjjl: '预计奖励',
  loading: '加载中...',
  zzqb: '支持钱包',
  qbsqtt: '钱包授权您的账户',
  sqinfo: '要使用您个人账户的功能，请使用TRX钱包授权',
  sqbtn: '钱包授权',
  sqload:'授权中...',
  dltt: '登录到您的账户',
  dlinfo: '要使用您个人账户的所有功能，请使用自动登录',
  dlbtn: '自动登录',
  xzlang: '选择语言',
  whqyqdz:'未获取到邀请人地址信息',
  bangsuc: "绑定成功",
  banger: '绑定失败',
  qxsq: '请您先授权！',
  bdyqrdz: '请您绑定邀请人地址！',
  qsydzdl:'请使用HECO地址登录游戏',
  

  yqlink:'邀请链接',
  yqinfo: '通过邀请链接分享给你的伙伴',
  yqr: '推荐人',



  fzcg: '复制成功',
  fzsb: '复制失败',
  buysuc: '开启成功',
  buyer: '开启失败',
  reasuc: '刷新成功',
  hzhb: '合作伙伴',
  ftjb: '复投级别',
  grztdhb: '个人直推的伙伴',
  sjhl: '上面来的滑落',
  xmhl: '下面来的滑落',
  yjhb: '越级的伙伴',
  zfgsj: '支付给上级',

  yjszgj: '已经到达最高级！',
  yjsftzdz: '已经是复投最大值了！',

  bdtt: '推荐人信息',
  bdinfo: '请填写推荐人地址（必填）',
  bdbtn: '确认',
  bdload:'绑定中...'
  
  

  
  
  
}