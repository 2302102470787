var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[(_vm.show)?_c('div',{staticClass:"mtoph5"},[_vm._m(0)]):_c('div',{staticClass:"mtop"},[_vm._m(1),_c('div',{staticClass:"btn",on:{"click":_vm.topage}},[_vm._v("Enter")])]),_c('div',{staticClass:"content"}),(_vm.show)?_c('div',{staticClass:"txth5"},[_vm._m(2),_c('div',{staticClass:"btn",on:{"click":_vm.topage}},[_vm._v("Enter")])]):_vm._e(),_c('div',{staticClass:"mbottom",class:_vm.show?'h5':''},[(_vm.show)?_c('div',{staticClass:"sbox"},[_c('div',{staticClass:"logos"}),_c('div',{staticClass:"emcard"},[_vm._m(3),_c('div',{staticClass:"txtx",on:{"click":function($event){return _vm.topage('/detail')}}},[_vm._v(" CLM Terms & Conditions ")])]),_vm._m(4)]):_c('div',{staticClass:"sbox"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"outer"},[_c('div',{staticClass:"imgline"}),_c('div',{staticClass:"txtx",on:{"click":function($event){return _vm.topage('/detail')}}},[_vm._v(" CLM Terms & Conditions ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/indeximg/logo.png"),"alt":"LOGO"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/indeximg/logo.png"),"alt":"LOGO"}})]),_c('div',{staticClass:"txt"},[_c('div',{staticClass:"t1"},[_vm._v("WELCOME TO CUPID LOVE METAVERSE ")]),_c('div',{staticClass:"t2"},[_vm._v(" A limited NFT collection，in which the token itself doubles as your qualification to enjoy various privileges and rights in the Cupid Love Metaverse. Cupid love metaverse is open! Join in with us.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('div',{staticClass:"t1"},[_vm._v("WELCOME TO CUPID LOVE METAVERSE ")]),_c('div',{staticClass:"t2"},[_vm._v(" A limited NFT collection，in which the token itself doubles as your qualification to enjoy various privileges and rights in the Cupid Love Metaverse. Cupid love metaverse is open! Join in with us.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"embox"},[_c('div',{staticClass:"txts"},[_vm._v("GET ON THE LIST")]),_c('div',{staticClass:"email"},[_c('input',{staticClass:"input",attrs:{"type":"text","placeholder":"Email Address"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer"},[_c('div',{staticClass:"imgline"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"embox"},[_c('div',{staticClass:"txts"},[_vm._v("GET ON THE LIST")]),_c('div',{staticClass:"email"},[_c('input',{staticClass:"input",attrs:{"type":"text","placeholder":"Email Address"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logos"},[_c('img',{attrs:{"src":require("../assets/indeximg/logo.png"),"alt":"LOGO"}})])
}]

export { render, staticRenderFns }